<template>
  <div class="web_down">
    <img
      :src="$t('AaAbordTop.wap' + $route.query.prev + 'download.logologo')"
      alt=""
      class="logo_logo"
    />
    <img
      :src="$t('AaAbordTop.wap' + $route.query.prev + 'download.logofont')"
      alt=""
      class="logo_font"
    />
    <div class="text_left wapfont14 clb100 det_det">
      {{ $t("AaAbordTop." + $route.query.prev + "download.det") }}
    </div>
    <div class="wap_eq">
      <div class="eq_item">
        <img
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.iosImg')"
          alt=""
          class="eq_img"
        />
        <!-- <div class="font24 clb100 eq_title">
          {{ $t("AaAbordTop." + $route.query.prev + "download.androidTitle") }}
        </div> -->
        <a
          :href="
            $route.query.prev === 'online' ? ios_quanqiugou : ios_haohuotong
          "
        >
          <img
            :src="$t('AaAbordTop.' + $route.query.prev + 'download.iosLogo')"
            alt=""
            class="down_logo"
          />
        </a>
      </div>
      <div class="eq_item">
        <img
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.androidImg')"
          alt=""
          class="eq_img"
        />
        <!-- <div class="font24 clb100 eq_title">
          {{ $t("AaAbordTop." + $route.query.prev + "download.iosTitle") }}
        </div> -->
        <img
          v-if="isWxClient && isMobileTerminal"
          :src="$t('AaAbordTop.' + $route.query.prev + 'download.androidLogo')"
          alt=""
          class="down_logo"
          @click="showGuid"
        />
        <a
          v-else
          :href="
            $route.query.prev === 'online'
              ? android_quanqiugou
              : android_haohuotong
          "
        >
          <img
            :src="
              $t('AaAbordTop.' + $route.query.prev + 'download.androidLogo')
            "
            alt=""
            class="down_logo"
          />
        </a>
      </div>
    </div>
    <!-- phonelogo -->
    <img
      :src="$t('AaAbordTop.wap' + $route.query.prev + 'download.phonelogo')"
      alt=""
      class="phone_logo"
    />
    <div v-if="show" class="guid_back" @click.stop="closeGuid">
      <div class="guid">
        <img src="@/assets/download/guid.png" alt="" />
      </div>
      <div class="mask_c">
        <div class="hint1">1. 点击右上角</div>
        <div class="hint2">
          {{
            !isIPhoneOrIPad
              ? "2. 点击`在浏览器中打开`"
              : "2. 点击`在Safari中打开`"
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid,
} from "@/utils/pattern";
export default {
  data() {
    return {
      show: false,
      ios_quanqiugou: "itms-apps://itunes.apple.com/app/6670529754",
      ios_haohuotong: "itms-apps://itunes.apple.com/app/6670604194",
      android_quanqiugou:
        "https://assets-apk.hooloogostore.com/store-retail/hlg.apk",
      android_haohuotong:
        "https://assets-apk.hooloogostore.com/store-distributor/jpt.apk",
    };
  },
  computed: {
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    },
  },
  methods: {
    showGuid() {
      if (!this.isWxClient) {
        return;
      }
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
.web_down {
  margin: calc(100vw * 5 / 37.5) calc(100vw * 4.6 / 37.5) 0;
  .det_det {
    // width: calc(100vw * 73.2 / 192);
    margin-top: calc(100vw * 2 / 37.5);
  }
  .eq_title {
    margin-top: calc(100vw * 2.4 / 192);
  }
}

.logo_logo {
  width: calc(100vw * 7.9 / 37.5);
  height: calc(100vw * 7.9 / 37.5);
  display: block;
  margin: 0 auto;
}
.logo_font {
  display: block;
  margin: 0 auto;
  width: calc(100vw * 10 / 37.5);
  margin-top: calc(100vw * 0.4 / 37.5);
}
.wap_eq {
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vw * 2.7 / 37.5);
  .eq_img {
    width: calc(100vw * 9 / 37.5);
  }
  .down_logo {
    margin-top: calc(100vw * 1.2 / 37.5);
    width: calc(100vw * 12 / 37.5);
  }
  .eq_item {
    width: calc(100vw * 12 / 37.5);
  }
}
.phone_logo {
  display: block;
  margin: 0 auto;
  margin-top: calc(100vw * 4.4 / 37.5);
  width: calc(100vw * 23.7 / 37.5);
}
.guid_back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}
.guid {
  text-align: right;
  padding: 8px 16px;
}
.guid img {
  width: 120px;
  height: 156px;
}
.mask_c {
  background: #fff;
  margin: 8px 24px auto 24px;
  border-radius: 5px;
  padding: 12px;
  font-size: 14px;
  color: #000;
  text-align: left;
}
</style>
